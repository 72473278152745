@use 'sass:math';
@import '../../../../styles/variables';

$logoWidth: 10rem;

// Logo
.unhab-Header-logo {
  width: $logoWidth;
  height: $headerHeight;
  display: flex;
  align-items: center;
  background: var(--accent50);
  padding: .15rem $mapWindowPadding_right 0;
}

.unhab-Header-logo svg {
  width: calc($logoWidth - 2 * $mapWindowPadding_right);
  height: $headerHeight;
}

.unhab-Header-logo > * {
  fill: #ffffff;
}